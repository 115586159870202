.notification {
  @extend .block;
  @include clearfix;
  background: $background;
  border-radius: $radius;
  padding: 16px 20px;
  position: relative;
  .delete {
    border-radius: 0 $radius;
    float: right;
    margin: -16px -20px 0 20px; }
  .subtitle,
  .title {
    color: inherit; }
  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background: $color;
      color: $color-invert; } } }
