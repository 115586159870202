.block {
  &:not(:last-child) {
    margin-bottom: 20px; } }

.container {
  position: relative;
  @include desktop {
    margin: 0 auto;
    max-width: 960px;
    // Modifiers
    &.is-fluid {
      margin: 0 20px;
      max-width: none; } }
  @include widescreen {
    max-width: 1200px; } }

.fa {
  font-size: 21px;
  text-align: center;
  vertical-align: top; }
