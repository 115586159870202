.media-number {
  background: $background;
  border-radius: 290486px;
  display: inline-block;
  font-size: $size-medium;
  height: 32px;
  line-height: 24px;
  min-width: 32px;
  padding: 4px 8px;
  text-align: center;
  vertical-align: top;
  // Responsiveness
  @include mobile {
    margin-bottom: 10px; }
  @include tablet {
    margin-right: 10px; } }

.media-left {
  margin-right: 10px; }

.media-right {
  margin-left: 10px; }

.media-content {
  flex: 1;
  text-align: left; }

.media {
  align-items: flex-start;
  display: flex;
  text-align: left;
  .content:not(:last-child) {
    margin-bottom: 10px; }
  .media {
    border-top: 1px solid rgba($border, 0.5);
    display: flex;
    padding-top: 10px;
    .textarea {
      @include control-small; }
    .button {
      @include button-small; }
    .content:not(:last-child),
    .control:not(:last-child) {
      margin-bottom: 5px; }
    .media {
      font-size: 12px;
      padding-top: 5px;
      & + .media {
        margin-top: 5px; } } }
  & + .media {
    border-top: 1px solid rgba($border, 0.5);
    margin-top: 10px;
    padding-top: 10px; }
  // Sizes
  &.is-large {
    & + .media {
      margin-top: 20px;
      padding-top: 20px; } }
  // Responsiveness
  @include tablet {
    &.is-large {
      .media-number {
        margin-right: 20px; } } } }
