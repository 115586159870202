.panel-icon {
  @include fa(14px, 16px);
  color: $text-light;
  float: left;
  margin: 0 4px 0 -2px;
  .fa {
    font-size: inherit;
    line-height: inherit; } }

.panel-heading {
  background: $background;
  border-bottom: 1px solid $border;
  border-radius: 4px 4px 0 0;
  color: $text-strong;
  font-size: $size-medium;
  font-weight: 300;
  padding: 10px; }

.panel-list {
  a {
    color: $text;
    &:hover {
      color: $link; } } }

.panel-tabs {
  display: flex;
  font-size: $size-small;
  padding: 5px 10px 0;
  justify-content: center;
  a {
    border-bottom: 1px solid $border;
    margin-bottom: -1px;
    padding: 5px;
    // Modifiers
    &.is-active {
      border-bottom-color: $link-active-border;
      color: $link-active; } }
  &:not(:last-child) {
    border-bottom: 1px solid $border; } }

.panel-block {
  color: $text-strong;
  display: block;
  line-height: 16px;
  padding: 10px;
  &:not(:last-child) {
    border-bottom: 1px solid $border; } }

a.panel-block {
  &:hover {
    background: $background; } }

.panel {
  border: 1px solid $border;
  border-radius: 5px;
  &:not(:last-child) {
    margin-bottom: 20px; } }
