.footer {
  background: $background;
  padding: 40px 20px 80px;
  a {
    &,
    &:visited {
      color: $text;
      &:hover {
        color: $text-strong; }
      &:not(.icon) {
        border-bottom: 1px solid $border;
        &:hover {
          border-bottom-color: $link; } } } } }
