.highlight {
  background-color: #fdf6e3;
  color: #586e75;
  .c {
    color: #93a1a1; }
  .err,
  .g {
    color: #586e75; }
  .k {
    color: #859900; }
  .l,
  .n {
    color: #586e75; }
  .o {
    color: #859900; }
  .x {
    color: #cb4b16; }
  .p {
    color: #586e75; }
  .cm {
    color: #93a1a1; }
  .cp {
    color: #859900; }
  .c1 {
    color: #93a1a1; }
  .cs {
    color: #859900; }
  .gd {
    color: #2aa198; }
  .ge {
    color: #586e75;
    font-style: italic; }
  .gr {
    color: #dc322f; }
  .gh {
    color: #cb4b16; }
  .gi {
    color: #859900; }
  .go,
  .gp {
    color: #586e75; }
  .gs {
    color: #586e75;
    font-weight: bold; }
  .gu {
    color: #cb4b16; }
  .gt {
    color: #586e75; }
  .kc {
    color: #cb4b16; }
  .kd {
    color: #268bd2; }
  .kn,
  .kp {
    color: #859900; }
  .kr {
    color: #268bd2; }
  .kt {
    color: #dc322f; }
  .ld {
    color: #586e75; }
  .m,
  .s {
    color: #2aa198; }
  .na {
    color: #B58900; }
  .nb {
    color: #586e75; }
  .nc {
    color: #268bd2; }
  .no {
    color: #cb4b16; }
  .nd {
    color: #268bd2; }
  .ni,
  .ne {
    color: #cb4b16; }
  .nf {
    color: #268bd2; }
  .nl,
  .nn,
  .nx,
  .py {
    color: #586e75; }
  .nt,
  .nv {
    color: #268bd2; }
  .ow {
    color: #859900; }
  .w {
    color: #586e75; }
  .mf,
  .mh,
  .mi,
  .mo {
    color: #2aa198; }
  .sb {
    color: #93a1a1; }
  .sc {
    color: #2aa198; }
  .sd {
    color: #586e75; }
  .s2 {
    color: #2aa198; }
  .se {
    color: #cb4b16; }
  .sh {
    color: #586e75; }
  .si,
  .sx {
    color: #2aa198; }
  .sr {
    color: #dc322f; }
  .s1,
  .ss {
    color: #2aa198; }
  .bp,
  .vc,
  .vg,
  .vi {
    color: #268bd2; }
  .il {
    color: #2aa198; } }
