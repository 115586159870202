html {
  background: $body-background;
  font-size: $size-normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility; }

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block; }

body,
button,
input,
select,
textarea {
  font-family: $family-primary; }

code,
pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: $family-code;
  line-height: 1.25; }

body {
  color: $text;
  font-size: 16px;
  font-weight: $weight-normal;
  font-family: $family-roboto;
  line-height: 1.428571428571429;
  background: url("/images/background.png") no-repeat 0 0 rgb(236, 236, 228);
  background-size: cover; }

// Inline

a {
  color: $link;
  cursor: pointer;
  text-decoration: none;
  transition: none $speed $easing;
  &:hover {
    color: $link-hover; } }

code {
  background: $code-background;
  color: $code;
  font-size: 12px;
  font-weight: normal;
  padding: 1px 2px 2px; }

hr {
  border-top-color: $border;
  margin: 20px 0; }

img {
  max-width: 100%; }

input[type="checkbox"],
input[type="radio"] {
  vertical-align: baseline; }

small {
  font-size: $size-small; }

span {
  font-style: inherit;
  font-weight: inherit; }

strong {
  color: $text-strong;
  font-weight: $weight-bold; }

// Block

pre {
  background: $pre-background;
  color: $pre;
  white-space: pre;
  word-wrap: normal;
  code {
    background: $pre-background;
    color: $pre;
    display: block;
    overflow-x: auto;
    padding: 16px 20px; } }

table {
  width: 100%;
  td,
  th {
    text-align: left;
    vertical-align: top; }
  th {
    color: $text-strong; } }
