@mixin button-small {
  border-radius: 2px;
  font-size: 11px;
  height: 24px;
  line-height: 16px;
  padding-left: 6px;
  padding-right: 6px; }
@mixin button-medium {
  font-size: 18px;
  height: 40px;
  padding-left: 14px;
  padding-right: 14px; }
@mixin button-large {
  font-size: 22px;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px; }

.button {
  @include control;
  @extend .unselectable;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  white-space: nowrap;
  strong {
    color: inherit; }
  small {
    display: block;
    font-size: $size-small;
    line-height: 1;
    margin-top: 5px; }
  .icon,
  .tag {
    &:first-child {
      margin-left: -2px;
      margin-right: 4px; }
    &:last-child {
      margin-left: 4px;
      margin-right: -2px; } }
  &:hover {
    color: $control-hover; }
  &:active {
    box-shadow: inset 0 1px 2px rgba($black, 0.2); }
  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background: $color;
      border-color: transparent;
      color: $color-invert;
      &:hover,
      &:focus {
        background: darken($color, 10%);
        border-color: transparent;
        color: $color-invert; }
      &:active {
        border-color: transparent; }
      &.is-inverted {
        background: $color-invert;
        color: $color;
        &:hover {
          background: darken($color-invert, 5%); } }
      &.is-loading {
        &:after {
          border-color: transparent transparent $color-invert $color-invert !important; } }
      &.is-outlined {
        background: transparent;
        border-color: $color;
        color: $color;
        &:hover,
        &:focus {
          background: $color;
          border-color: $color;
          color: $color-invert; } } } }
  &.is-link {
    background: transparent;
    border-color: transparent;
    color: $text;
    text-decoration: underline;
    &:hover,
    &:focus {
      background: $border;
      color: $text-strong; } }
  // Sizes
  &.is-small {
    @include button-small; }
  &.is-medium {
    @include button-medium; }
  &.is-large {
    @include button-large; }
  // Modifiers
  &[disabled],
  &.is-disabled {
    opacity: 0.5; }
  &.is-fullwidth {
    display: block;
    width: 100%; }
  &.is-loading {
    color: transparent !important;
    pointer-events: none;
    &:after {
      @extend .loader;
      @include center(16px);
      position: absolute !important; } } }
