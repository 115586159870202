.portfolio {
  background: #e7d6c7;
  padding: 120px;

  @include mobile {
    padding: 60px; }

  .container {
    max-width: 980px;
    margin: 0 auto; }

  .column {
    padding: 0;
    margin: 5px; }

  .portfolio-item {
    filter: #{"grayscale(0.7)"};
    border-radius: 100%;
    text-align: center;
    position: relative;
    max-width: 300px;
    opacity: .9;
    transition: all 0.5s ease-in;

    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: default;

    @include mobile {
      margin: 0 auto; }

    img {
      border-radius: 100%; }

    &:hover {
      filter: #{"grayscale(0)"};
      opacity: 1;
      transition: all 0.1s ease-out; }

    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0; }

    img {
      display: block;
      position: relative;
      transition: all 0.4s ease-in; }

    &:hover {
      img {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feGaussianBlur stdDeviation="3" /></filter></svg>#filter');
        filter: grayscale(1) blur(3px);
        transform: scale(1.2); }

      h2 {
        top: 30%; }

      a.info,
      h2 {
        opacity: 1;
        filter: alpha(opacity=100);
        transform: scale(1); } }

    h2 {
      text-transform: uppercase;
      text-align: center;
      position: relative;
      font-size: 14px;
      padding: 10px;
      background: rgba(0, 0, 0, 0.6);
      top: 0;
      width: 200%;
      left: -50%;
      font-weight: bold;
      color: #fff; }

    a.info {
      display: inline-block;
      text-decoration: none;
      padding: 13px 30px;
      border: 1px solid #fff;
      margin: 35% 0 0 0;
      background-color: rgba(0, 0, 0, 0.3);
      font-size: 12px;
      border-radius: 4px;
      line-height: 12px;
      font-weight: bold;
      color: #fff;

      &:hover {
        box-shadow: 0 0 5px #fff; } }

    a.info,
    h2 {
      transform: scale(0.7);
      transition: all 0.4s cubic-bezier(0.18, 0.89, 0.32, 1.28);
      opacity: 0;
      filter: alpha(opacity=0);
      text-transform: uppercase; } } }

