.box {
  @extend .block;
  background: $white;
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1);
  display: block;
  padding: 20px; }

a.box {
  &:hover,
  &:focus {
    box-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px $link; }
  &:active {
    box-shadow: inset 0 1px 2px rgba($black, 0.2), 0 0 0 1px $link; } }
