//
//  HTML5 Reset :: style.css
//  ----------------------------------------------------------
//  We have learned much from/been inspired by/taken code where offered from:
//
//  Eric Meyer          :: http://meyerweb.com
//  HTML5 Doctor        :: http://html5doctor.com
//  and the HTML5 Boilerplate :: http://html5boilerplate.com
//
//-------------------------------------------------------------------------------

// Let's default this puppy out

html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary {
  display: block; }

// Handle box-sizing while better addressing child elements:
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

// consider resetting the default cursor: https://gist.github.com/murtaugh/5247154

// Responsive images and other embedded objects
img,
object,
embed {
  max-width: 100%; }

//
//   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
//   In fact, it *will* cause problems with Google Maps' controls at small size.
//  If this is the case for you, try uncommenting the following:
//
//#map img {
//    max-width: none;
//}

// force a vertical scrollbar to prevent a jumpy page
html {
  overflow-y: scroll; }

// we use a lot of ULs that aren't bulleted.
//  don't forget to restore the bullets within content.
ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

// tables still need cellspacing="0" in the markup
table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  // CSS2
  white-space: pre-wrap;
  // CSS 2.1
  white-space: pre-line;
  // CSS 3 (and 2.1 as well, actually)
  word-wrap: break-word;
 }  // IE

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

small {
  font-size: 85%; }

strong {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

// Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

// standardize any monospaced elements
pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

// hand cursor on clickable elements
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer; }

// Webkit browsers add a 2px margin outside the chrome of form elements
button, input, select, textarea {
  margin: 0; }

// make buttons play nice in IE
button,
input[type=button] {
  width: auto;
  overflow: visible; }
