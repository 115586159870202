.modal-background {
  @include overlay;
  background: rgba($black, 0.86); }

.modal-content {
  margin: 0 20px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;
  width: 100%;
  // Responsiveness
  @include tablet {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 90%; } }

.modal-close {
  @extend .delete;
  background: none;
  height: 40px;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 40px; }

.modal-card {
  @extend .modal-content;
  background: $white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  overflow: hidden; }

.modal-card-head,
.modal-card-foot {
  align-items: center;
  background: $background;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 20px;
  position: relative; }

.modal-card-head {
  border-bottom: 1px solid $border; }

.modal-card-title {
  color: $text-strong;
  flex: 1;
  font-size: $size-4;
  line-height: 1; }

.modal-card-foot {
  border-top: 1px solid $border;
  .button {
    &:not(:last-child) {
      margin-right: 10px; } } }

.modal-card-body {
  flex: 1;
  overflow: auto;
  padding: 20px; }

.modal {
  @include overlay;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 1986;
  // Modifiers
  &.is-active {
    display: flex; } }
