.menu-nav {
  a {
    display: block;
    padding: 5px 10px; } }

.menu-list {
  a {
    border-radius: 2px;
    color: $text;
    display: block;
    padding: 5px 10px;
    &:hover {
      background: $background;
      color: $link; }
    // Modifiers
    &.is-active {
      background: $link;
      color: $link-invert; } }
  li {
    ul {
      border-left: 1px solid $border;
      margin: 10px;
      padding-left: 10px; } } }

.menu-label {
  color: $text-light;
  font-size: $size-small;
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-transform: uppercase;
  &:not(:first-child) {
    margin-top: 20px; } }
