.card-header {
  align-items: stretch;
  box-shadow: 0 1px 2px rgba($black, 0.1);
  display: flex;
  min-height: 40px; }

.card-header-title {
  align-items: flex-start;
  color: $text-strong;
  display: flex;
  flex: 1;
  font-weight: bold;
  padding: 10px; }

.card-header-icon {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 40px; }

.card-image {
  display: block;
  position: relative; }

.card-content {
  padding: 20px;
  .title + .subtitle {
    margin-top: -20px; } }

.card-footer {
  border-top: 1px solid $border;
  align-items: stretch;
  display: flex; }

.card-footer-item {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 10px;
  &:not(:last-child) {
    border-right: 1px solid $border; } }

.card {
  background: $white;
  box-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1);
  color: $text;
  max-width: 100%;
  position: relative;
  width: 300px;
  .media:not(:last-child) {
    margin-bottom: 10px; }
  // Modifiers
  &.is-fullwidth {
    width: 100%; }
  &.is-rounded {
    border-radius: 5px; } }
