.footer-spacer {
  margin-bottom: 300px;
  background: transparent; }

.contacts {
  padding: 10px 0;
  background: url("/images/arrow.png") no-repeat 98% 49% #c1dad6;
  height: 300px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: -1;

  @include mobile {
    background-image: none; }

  header {
    font-size: 24px;
    color: #18107a;
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 10px; }

  .copy {
    font-size: 10px;
    text-transform: uppercase;
    margin-top: 10px; }

  .social-links {
    margin: 20px 0;

    a {
      margin-right: 20px;

      img {
        vertical-align: middle; }

      &:last-child {
        margin-right: 0; } } }

  .socil-icon {
    width: 54px;
    height: 54px;
    display: inline-block;
    color: transparent;
    vertical-align: middle;
    border-radius: 100%;
    background: url("/images/social-icons.png") no-repeat #1f7f9f;

    &.email {
      background-position: 0 0;
      &:hover {
        background-position: 0 -54px; } }

    &.facebook {
      background-position: -54px 0;
      &:hover {
        background-position: -54px -54px; } }

    &.gplus {
      background-position: -108px 0;
      &:hover {
        background-position: -108px -54px; } }

    &.skype {
      background-position: -162px 0;
      &:hover {
        background-position: -162px -54px; } } } }
