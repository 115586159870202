@mixin control {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  background: $control-background;
  border: 1px solid $control-border;
  border-radius: $radius;
  color: $control;
  display: inline-flex;
  font-size: $size-normal;
  height: 32px;
  justify-content: flex-start;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  vertical-align: top;
  &:hover {
    border-color: $control-hover-border; }
  &:active,
  &:focus {
    border-color: $control-active-border;
    outline: none; }
  &[disabled],
  &.is-disabled {
    background: $background;
    border-color: $control-border;
    cursor: not-allowed;
    pointer-events: none;
    @include placeholder {
      color: rgba($control, 0.3); } } }

@mixin control-small {
  border-radius: 2px;
  font-size: 11px;
  height: 24px;
  line-height: 16px;
  padding-left: 6px;
  padding-right: 6px; }
@mixin control-medium {
  font-size: 18px;
  height: 40px;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 10px; }
@mixin control-large {
  font-size: 24px;
  height: 48px;
  line-height: 40px;
  padding-left: 12px;
  padding-right: 12px; }
