.index {
  .hero-body {
    padding-bottom: 0; }

  .hero-body,
  .hero-foot {
    background: #e0ebe8; }

  &-title {
    margin-bottom: 47px; }

  &-subtitle {
    font-size: 20px;
    margin-bottom: 60px; }

  .nav-toggle {
    position: absolute;
    top: 0;
    right: 0; } }

.start {
  background: #e0ebe8;
  position: relative;

  .heading {
    .title {
      margin-bottom: 45px; }

    .subtitle {
      color: $default-color; } } }

.clouds {
  .cloud {
    position: absolute;

    &-1 {
      right: 0;
      top: -40px; }

    &-2 {
      left: 0;
      top: 20px; } } }

.mountains {
  position: relative;
  display: inline-block;

  img {
    vertical-align: top; } }

.color-blue {
  color: #3398b9; }
