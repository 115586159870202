@mixin form-control {
  @include control;
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    &.is-#{$name} {
      border-color: $color; } } }

.input {
  @include form-control;
  box-shadow: inset 0 1px 2px rgba($black, 0.1);
  max-width: 100%;
  width: 100%;
  &[type="search"] {
    border-radius: 290486px; }
  // Sizes
  &.is-small {
    @include control-small;
    &.is-flat {
      padding: 4px 6px; } }
  &.is-medium {
    @include control-medium;
    &.is-flat {
      padding: 4px 10px; } }
  &.is-large {
    @include control-large;
    &.is-flat {
      padding: 4px 12px; } }
  // Modifiers
  &.is-flat {
    border: none;
    box-shadow: none; }
  &.is-fullwidth {
    display: block;
    width: 100%; }
  &.is-inline {
    display: inline;
    width: auto; } }

.textarea {
  @extend .input;
  display: block;
  line-height: 1.2;
  max-height: 600px;
  max-width: 100%;
  min-height: 120px;
  min-width: 100%;
  padding: 10px;
  resize: vertical; }

%control-with-element {
  cursor: pointer;
  display: inline-block;
  line-height: 16px;
  position: relative;
  vertical-align: top;
  input {
    cursor: pointer; }
  &:hover {
    color: $control-hover; }
  &.is-disabled {
    color: $text-light;
    pointer-events: none;
    input {
      pointer-events: none; } } }

.checkbox {
  @extend %control-with-element; }

.radio {
  @extend %control-with-element;
  & + .radio {
    margin-left: 10px; } }

.select {
  display: inline-block;
  height: 32px;
  position: relative;
  vertical-align: top;
  select {
    @include form-control;
    cursor: pointer;
    display: block;
    outline: none;
    padding-right: 36px;
    &:hover {
      border-color: $control-hover-border; }
    &::ms-expand {
      display: none; } }
  &.is-fullwidth {
    width: 100%;
    select {
      width: 100%; } }
  &:after {
    @include arrow($link);
    margin-top: -6px;
    right: 16px;
    top: 50%; }
  &:hover {
    &:after {
      border-color: $link-hover; } } }

.label {
  color: $text-strong;
  display: block;
  font-weight: bold;
  &:not(:last-child) {
    margin-bottom: 5px; } }

.help {
  display: block;
  font-size: $size-small;
  margin-top: 5px;
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    &.is-#{$name} {
      color: $color; } } }

// Containers

.control-label {
  @include mobile {
    margin-bottom: 5px; }
  @include tablet {
    flex: 1;
    margin-right: 20px;
    padding-top: 7px;
    text-align: right; } }

.control {
  position: relative;
  text-align: left;
  &:not(:last-child) {
    margin-bottom: 10px; }
  // Modifiers
  &.has-addons {
    display: flex;
    justify-content: flex-start;
    .button,
    .input,
    .select {
      border-radius: 0;
      margin-right: -1px;
      &:hover {
        z-index: 2; }
      &:active,
      &:focus {
        z-index: 3; }
      &:first-child {
        border-radius: $radius 0 0 $radius;
        select {
          border-radius: $radius 0 0 $radius; } }
      &:last-child {
        border-radius: 0 $radius $radius 0;
        select {
          border-radius: 0 $radius $radius 0; } } }
    &.has-addons-centered {
      justify-content: center; }
    &.has-addons-right {
      justify-content: flex-end; } }
  &.has-icon {
    & > .fa {
      @include fa(14px, 24px);
      color: $text-light;
      pointer-events: none;
      position: absolute;
      top: 4px;
      z-index: 4; }
    .input {
      &:focus + .fa {
        color: $text-strong; }
      &.is-small + .fa {
        font-size: 10.5px;
        top: 0; }
      &.is-medium + .fa {
        font-size: 21px;
        top: 8px; }
      &.is-large + .fa {
        font-size: 21px;
        top: 12px; } }
    &:not(.has-icon-right) {
      & > .fa {
        left: 4px; }
      .input {
        padding-left: 32px;
        &.is-small {
          padding-left: 24px;
          & + .fa {
            left: 0; } }
        &.is-medium {
          padding-left: 40px;
          & + .fa {
            left: 8px; } }
        &.is-large {
          padding-left: 48px;
          & + .fa {
            left: 12px; } } } }
    &.has-icon-right {
      & > .fa {
        right: 4px; }
      .input {
        padding-right: 32px;
        &.is-small {
          padding-right: 24px;
          & + .fa {
            right: 0; } }
        &.is-medium {
          padding-right: 40px;
          & + .fa {
            right: 8px; } }
        &.is-large {
          padding-right: 48px;
          & + .fa {
            right: 12px; } } } } }
  &.is-grouped {
    display: flex;
    justify-content: flex-start;
    & > .button,
    & > .input,
    & > .select {
      &:not(:last-child) {
        margin-right: 10px; } }
    & > .input {
      flex: 1; }
    &.is-grouped-centered {
      justify-content: center; }
    &.is-grouped-right {
      justify-content: flex-end; } }
  &.is-horizontal {
    @include tablet {
      display: flex;
      & > .control {
        display: flex;
        flex: 5; } } }
  &.is-loading {
    &:after {
      @extend .loader;
      position: absolute !important;
      right: 8px;
      top: 8px; } } }
