.navigation {
  width: 100%; }

.nav {
  padding: 0 160px;
  height: 130px;

  @include mobile {
    padding: 0; }

  .nav-logo-container {
    align-self: center;

    @include mobile {
      width: 100%; } }

  .nav-logo {
    align-items: center; }

  .nav-item {
    color: $default-color;
    font-weight: 500;
    font-size: 20px;

    &:hover {
      color: $default-color-hover;

      span {
        border-bottom: 1px solid; } } } }
