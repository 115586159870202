.pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  a {
    @extend .button;
    display: block;
    min-width: 32px;
    padding: 3px 8px;
    // Modifiers
    &.is-active {
      background: $link;
      border-color: $link;
      color: $link-invert; } }
  span {
    color: $text-light;
    display: block;
    margin: 0 4px; }
  li {
    margin: 0 2px; }
  ul {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center; }
  // Responsiveness
  @include mobile {
    flex-wrap: wrap;
    & > a {
      width: calc(50% - 5px);
      &:not(:first-child) {
        margin-left: 10px; } }
    li {
      flex: 1; }
    ul {
      margin-top: 10px; } }
  @include tablet {
    & > a {
      &:not(:first-child) {
        order: 1; } } } }
