.message-body {
  border: 1px solid $border;
  border-radius: $radius;
  padding: 12px 15px;
  strong {
    color: inherit; } }

.message-header {
  background: $text;
  border-radius: $radius $radius 0 0;
  color: $text-invert;
  padding: 7px 10px;
  strong {
    color: inherit; }
  & + .message-body {
    border-radius: 0 0 $radius $radius;
    border-top: none; } }

.message {
  @extend .block;
  background: $background;
  border-radius: $radius;
  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    $lightning: max((100% - lightness($color)) - 4%, 0%);
    $darkness: max(lightness($color) - 10%, lightness($color));
    &.is-#{$name} {
      background: lighten($color, $lightning);
      .message-header {
        background: $color;
        color: $color-invert; }
      .message-body {
        border-color: $color;
        @if (colorLuminance($color) > 0.8) {
          color: desaturate(lighten(darken($color, 100%), 40%), 40%); }
        @else {
          color: desaturate(lighten(darken($color, 100%), 50%), 30%); } } } } }
