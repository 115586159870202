.section {
  padding: 40px 20px;
  // Responsiveness
  @include desktop {
    padding: 40px 0;
    // Sizes
    &.is-medium {
      padding: 120px 0; }
    &.is-large {
      padding: 240px 0; } } }
