.content {
  @extend .block;
  // Inline
  a {
    border-bottom: 1px solid $border;
    &:visited {
      color: $link-visited; }
    &:hover {
      border-bottom-color: $link; } }
  li + li {
    margin-top: 0.25em; }
  // Block
  blockquote,
  p,
  ol,
  ul {
    &:not(:last-child) {
      margin-bottom: 1em; } }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $text-strong;
    font-weight: 300;
    line-height: 1.125;
    margin-bottom: 20px; }
  h1,
  h2,
  h3 {
    &:not(:first-child) {
      margin-top: 40px; } }
  blockquote {
    background: $background;
    border-left: 5px solid $border;
    padding: 1.5em; }
  h1 {
    font-size: 2em; }
  h2 {
    font-size: 1.75em; }
  h3 {
    font-size: 1.5em; }
  h4 {
    font-size: 1.25em; }
  h5 {
    font-size: 1.125em; }
  h6 {
    font-size: 1em; }
  ol {
    list-style: decimal outside;
    margin-left: 2em;
    margin-right: 2em;
    margin-top: 1em; }
  ul {
    list-style: disc outside;
    margin-left: 2em;
    margin-right: 2em;
    margin-top: 1em;
    ul {
      list-style-type: circle;
      margin-top: 0.5em;
      ul {
        list-style-type: square; } } }
  // Sizes
  &.is-medium {
    font-size: $size-5;
    code {
      font-size: $size-6; } }
  &.is-large {
    font-size: $size-4;
    code {
      font-size: $size-5; } } }
