.about {
  //height: 1620px

  .ellipsis {
    margin: 80px 0;

    @include mobile {
      margin: 40px 0; } }

  .description-text {
    font-size: 18px; }

  &-heading {
    margin-bottom: 62px;

    .title {
      font-size: 46px;

      @include mobile {
        font-size: 36px; } } } }

.what-i-can-do {
  &-img {
    text-align: right;
    white-space: nowrap;

    @include mobile {
      text-align: center;
      margin-bottom: 10px; }

    .spacer {
      height: 3px;
      width: 60px;
      content: '';
      background-color: #E7DBC7;
      display: inline-block;
      position: relative;
      z-index: 0;
      margin: 0 -10px; }

    & > img {
      vertical-align: middle;
      position: relative;
      z-index: 1; } }

  &-desc {
    h3 {
      color: #6bbdd5;
      font-size: 24px;
      margin-bottom: 26px;

      @include mobile {
        text-align: center; } }

    .text {
      color: #444;
      line-height: 1.85em; } } }

.work-text {
  margin: 80px 0;

  @include mobile {
    margin: 40px 0; } }
